.bannerTitle { 
    font-size: 13px;
    color: #121D22;
    font-family: Montserrat;
    font-weight: 600;
    padding-top: 8px;
  }

  .ref p, span {
    margin: 0;
    padding: 0;
  }
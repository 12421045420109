.Full_arae{

    max-width: 1280px;
    font-size: 21px;
    margin: 0 auto 20px auto;
    
    font-family: Montserrat;
}

.responsive-iframe {
   width:100%;
   height:610px;
   margin-top: -48px;
  }
p{
    margin: 10px 0px;
    font-size: 19px;
}
.coverarea{
    width:100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.division{
   width: 50%;
}

.division>p{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.74);
    width:70%;
}

.division h2 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.74);
}

.contactContainer {
    overflow: hidden;
}

@media (max-width:992px){

    .division{
        width:100%;
    }
  }
  
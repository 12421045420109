.blink {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .MuiTextField-root .MuiInputBase-root:hover:not(.Mui-disabled):before {
    border: 1px solid rgba(0, 0, 0, 0.42) !important;
  }
  .MuiInputBase-input:focus,.MuiInputBase-input:hover{
      border: none !important;
  }
  .MuiSelect-select:focus,.MuiSelect-select:hover{
    background-color:none !important;
  }
.accordion__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin:4px 0;
 border-bottom: solid 2px;
}

.accordion__section:first-child {
  margin-top:0;
}

.accordion__section:last-child {
  margin-bottom:0;
  border-bottom: none;
}

.accordion {
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.active .arrowDown {
  transform: rotateZ(180deg);
}

.active {
  /* background-color: #25ABC7; */
}

.active .accordion__title{
  /* color:#fff !important; */
}

.accordion.active {
/* padding: 12px; */
}

/* Style the accordion content title */
.accordion__title {
  color: rgba(0,0,0,0.87);
  font-family: Montserrat;
  font-size:14px;
  font-weight: 600;
  text-align:left;
}

.accordion__content {
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
}

.accordion__text {
  color: rgba(0,0,0,0.87);
  font-family: Montserrat;
  font-size:14px;
  font-weight: 500;
  text-align:left;
  padding: 0 15px 15px;
  margin: 0;
}

.accordion__text p{
  color: rgba(0,0,0,0.87);
  font-family: Montserrat;
  font-size:14px;
  font-weight: 500;
  text-align:left;
  padding: 0 0 15px 0;
  margin: 0;
}

@media (max-width: 780px){
    .feedBtnStyle {
        padding:16px 12px 7px 46px !important;
        min-width: 181px !important;
        bottom: 56px !important;
    }
}

@media (max-width: 780px){
    .feedBtnStyles {
       
        bottom: 54px !important;
    }
}

.feedBtnStyle {
  font-family: 'Montserrat';
  font-size:16px;
  background: #34495e;
  color:#fff;
  font-weight: 600;
  z-index: 99;
  padding:16px 29px 7px 59px;
  position:fixed;
  border-radius:5px 5px 0 0;
  border: none;
  bottom:0;
  background-color:#1A4664;
  cursor: pointer;
  min-width: 222px;
  right: 8% !important;
}

.feedBtnStyles {
  padding:16px 89px 4px 44px;
  font-size:16px;
  background: #34495e;
  color:#fff;
  font-weight: 600;
  z-index: 99;
  padding:16px 29px 4px 59px;
  position:fixed;
  border-radius:5px 5px 0 0;
  border: none;
  bottom:0;
  background-color:#7EB1CC;
  cursor: pointer;
  min-width: 222px !important;
  right: 8% !important;
}


.switchContainer {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 30px;
}

.switchContainer input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.sliders {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.38);
  -webkit-transition: .4s;
  transition: .4s;
}

.sliders:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0px;
  background-color: #3DBA21;
  -webkit-transition: .4s;
  transition: .4s;  
}

input + .sliders:before {
  background-color:#fff;
  border: 1px solid #c6c4c4;
}

input:checked + .sliders:before {
  background-color: #3DBA21;
  border: 'none';
}

input:checked + .sliders {
  background-color: rgba(61,186,33,0.38);
}

input:focus + .sliders {
  box-shadow: 0 0 1px #52b752;
}

input:checked + .sliders:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.sliders.round {
  border-radius: 34px;
}

.sliders.round:before {
  border-radius: 50%;
}

.title{
    display: flex;
    align-items: center;
    padding-left: 6px;
    font-size: 6px;
    color: '#747474';
    font-weight: 600;
    font-family: 'Montserrat';
    height:15px;
}

.offSwitch{
    padding-left: 9px;
}

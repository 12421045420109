/* .container{
    width:100%;
}

.about-us {
    height: 49px;
    width: 206px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 49px;
    text-shadow: 1px 1px 11px 0 rgba(0,0,0,0.5);
    margin: 25px auto;
    
  }
  .rectangle_1{
    box-sizing: border-box;
    height: 313px;
    width: 1020px;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    
    margin: 0 auto;
    position:relative;
    margin-bottom: 50px;
    background-color: #FFFFFF;
  }
  .rectangle_1>.pics{
    width:267px;
    height:235px;
    position: absolute;
    
    top:40px;
    left:-104px;
}
.pics img{
 
  width: 100%;
  height: auto;
  border-radius: 20px;
  border-right: 6px solid rgba(0,0,0,0.2);
  border-bottom: 6px solid rgba(0,0,0,0.2);
}

  .rectangle_2{
    box-sizing: border-box;
    background-color: #FFFFFF;
    margin-bottom: 50px;
    width: 1020px;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    margin: 0 auto;
    position:relative;
  }

  .rectangle_2>.pics{
    width:267px;
    height:293px;
    position: absolute;
    top:36px;
    right:-102px;
  }




  .incider_text{
      width:714px;
      margin-top: 68px;
      margin-left: 228px;
  }
  .incider_text_2{
    width:714px;
    margin-top: 75px;
    margin-bottom: 75px;
    margin-left: 78px;
}
.incider_text_2>span{
    
    width: 100%;
    color: #535D6D;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 24px;
  }
  .incider_text_2>ul{
    
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 35px;
  }
  .incider_text>span{
    
    width: 100%;
    color: #535D6D;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 24px;
  }
  .incider_text>p{
    
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .click-here-to-view-o {
    
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
  }

  .leadership{
      width:100%;
  }
  .leadershipcontainer{
      max-width:1300px;
      width: 1280px;
      margin-top: 190px;
  }
  .area{
      width:100%;
        height:150px
  }
  .incr{
      width:357px !important;
  }
  .division_leader{
    
    box-sizing: border-box;
    background-color: #FFFFFF;
    width: 384px;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    float:left;
    margin-right: 20px;
    position: relative;
  }
  .division_leader > .pics{
    width: 228px;
    height: 228px;
    position: absolute;
    bottom: 81px;
    right: 75px;
  }
  .division_leader > .textarea{
      width: 328px;
      margin: 0 auto;
      margin-top: 81px;
      text-align: center;

  }
  .division_leader > .textarea > span{
    color: rgba(0,0,0,0.87);
    font-family: Montserrat;
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 24px;
    
  }
  .division_leader > .textarea > p{
    color: rgba(0,0,0,0.87);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: center;
  }
  .leadership>span{
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 49px;
    text-shadow: 1px 1px 11px 0 rgba(0,0,0,0.5);
    margin: 45px auto;
  }



  @media (max-width:600px){

    .rectangle_1{
        box-sizing: border-box;
        height: auto;
        width: 331px;
        border: 2px solid #F7B15C;
        border-radius: 12px;
        margin: 0 auto;
        position:relative;
        margin-bottom: 50px;
        background-color: #FFFFFF;
        margin-top: 105px;
        left:0px !important;
      }
      .gett{
        margin-top: 130px !important;
      }
      .container{
        transform: none !important;
    }
      .rectangle_1>.pics{
        width: 267px;
        height: 235px;
        position: absolute;
        
        top: -86px;
        left: 28px;
    }
    .rectangle_2{
        box-sizing: border-box;
        background-color: #FFFFFF;
        margin-bottom: 50px;
        width: 331px;
        
        border: 2px solid #F7B15C;
        border-radius: 12px;
        margin: 0 auto;
        position:relative;
        right:0px !important;
        margin-top: 110px;
      }
    
      .rectangle_2>.pics{
        width:267px;
        height:235px;
        position: absolute;
        top:-86px;
        left:28px;
      }
    .incider_text{
        width: 300px;
        margin-top: 172px;
        margin-left: 13px;
    }
    .incider_text_2{
        width:300px;
        margin-top: 232px;
        
        margin-left: 13px;
    }
    .division_leader{
        box-sizing: border-box;
        background-color: #FFFFFF;
        width: 351px;
        border: 2px solid #F7B15C;
        border-radius: 12px;
        float: none;
        position: relative;
        
        margin-bottom: 225px;
      }
      .division_leader > .pics{
        width: 228px;
        height: 228px;
        position: absolute;
        bottom: 81px;
        right: 59px;
      }
      .division_leader > .textarea{
          width: 271px;
          margin: 0 auto;
          margin-top: 81px;
          text-align: center;
    
      }
      .division_leader > .textarea > span{
        color: rgba(0,0,0,0.87);
        font-family: Montserrat;
        font-size: 20px;
        letter-spacing: 0.15px;
        line-height: 24px;
        
      }
      .division_leader > .textarea > p{
        color: rgba(0,0,0,0.87);
        font-family: Montserrat;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        text-align: center;
      }

      .area {
        width: 100%;
        height:auto;
    }

      .leadershipcontainer {
        position: relative; 
         right: 0px !important;
         width:355px;
         margin:0 auto;
         margin-top: 180px;
    }
  }

  @media (max-width:1200px){

    .container{
        transform: scaleX(0.9);
    }
    .rectangle_1{
      left:45px;
    }
    .rectangle_2{
        right:49px;
    }
    .leadershipcontainer{
        position: relative;
        right: 85px;
        transform: scaleX(0.95);
    }
  }

  @media (max-width:1024px){

    .container{
        transform: scaleX(0.7);
    }
    .rectangle_2{
        right:84px;
    }
    .leadershipcontainer{
        position: relative;
        right: 119px;
    }
  }
  @media only screen and (max-width: 900px) and (min-width: 600px)  {
    .container{
      transform: scaleX(0.7);
  }
  .rectangle_1{
    left:-32px;
  }
  .rectangle_2{
      right:131px !important;
  }
  .leadershipcontainer{
      position: relative;
      right: 176px !important;
  }
  }
   */
   .whole_area{
max-width:840px;
padding:10px;
   }
.border_box{
  box-sizing: border-box;
  width:100%;
  border: 2px solid #F7B15C;
  border-radius: 12px;
  overflow: hidden;
  padding:20px;
  margin-bottom: 25px;
  background-color: #EAECED;
}
.form450{
  width: 100%;
  /* float: left; */
  margin-top: 20px;
}
.form450 p{
  color: rgba(0,0,0,0.87);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.form450 span{
  color: rgba(0,0,0,0.74);
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  width: 100%;
  display: inline-block;
}
.downlodd{
  width: 100%;
  /* float: left; */
  display: flex;
  justify-content: space-between;
 
}

@media  (max-width: 700px)  {
  .downlodd{
    flex-wrap:wrap;
  }
}

.section{
  width:70%;
}
@media  (max-width: 700px)  {
  .section{
    width:100%;
  }
}

.widbutton{
  max-width: 220px;
  width:220px;
}
.designbutton{
  width: 100%;
  text-align: center;
  border-radius: 4px;
  background-color: rgb(48, 100, 166);
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.43px;
  line-height: 16px;
  padding: 10px 0px;
  display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.designbutton a{
  text-decoration: none;
  color:#fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.43px;
  line-height: 16px;
}
.dividerr{
  width: 85%;
  opacity: 0.12;
  background-color: #000000;
  height: 1px;
  margin-bottom: 15px;
  margin-top: 17px;
}
.firsthead{
  color: rgba(0,0,0,0.87);
  font-family: Montserrat;
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.secondhead{
  color: rgba(0,0,0,0.74);
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.FILLFORM_icon{
  transform: rotateY(180deg);

}
.movv{
  margin-top: 10px;
  cursor:pointer;
  background-color: #EAECED;
  color: #535D6D;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  letter-spacing: 1.43px;
  border: 2px solid #F7B15C;
  line-height: 16px;

  /* display: flex;
    align-items: center;
    justify-content: space-evenly; */
}

@media  (max-width: 900px)  {


  .form450 {
    width: 100%;
}

.downlodd {
  
  margin-top: 10px;
}

}

/* @media (max-width: 1100px)  {

  .border_box{
    width:97%;
  }

}

@media  (max-width: 900px)  {

  .border_box{
    width:94%;
  }
  .form450{
    width:66%;
  }

}

@media  (max-width: 850px)  {

  .border_box{
    width:91%;
  }


}

@media  (max-width: 823px)  {

  .border_box{
    width:86%;
  }


} */

/* @media  (max-width: 800px)  {

  .border_box{
    width:81%;
  }


}

@media  (max-width: 767px)  {

  .border_box{
    width:74%;
  }
  .form450 {
    width: 59%;
}

}

@media  (max-width: 710px)  {

  .border_box{
    width:70%;
  }
  .form450 {
    width: 100%;
}
.downlodd {
  
  margin-top: 10px;
}

}

@media  (max-width: 669px)  {

  .border_box{
    width:62%;
  }
  .form450 {
    width: 100%;
}


}

@media  (max-width: 597px)  {

  .border_box{
    width:58%;
  }
  .form450 {
    width: 100%;
}


}

@media  (max-width: 568px)  {

  .border_box{
    width:53%;
  }
  .form450 {
    width: 100%;
}


}

@media  (max-width: 527px)  {

  .border_box{
    width:49%;
  }
  .form450 {
    width: 100%;
}


}

@media  (max-width: 500px)  {

  .border_box{
    width:44%;
  }
  .form450 {
    width: 100%;
}


}

@media  (max-width: 475px)  {

  .border_box{
    width:40%;
  }
  .form450 {
    width: 100%;
}


}

@media  (max-width: 425px)  {

  .border_box{
    width:35%;
  }
  .form450 {
    width: 100%;
}


} */
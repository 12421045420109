.switches {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.switches input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slideres {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #CFCFCF;
  -webkit-transition: .4s;
  transition: .4s;
}

.slideres:before {
  position: absolute;
  content: "";
  height: 31px;
  width: 30px;
  left: 0px;
  /* bottom: 4px; */
  background-color: #FFC690;
  -webkit-transition: .4s;
  transition: .4s;
}


input + .slideres:before {
  background-color:#fff;
  border: 1px solid #c6c4c4;
}

input:checked + .slideres:before {
  background-color: #fff;
  border: 'none';
}

input:checked + .slideres {
  background-color: #FFC690;
}


input:focus + .slideres {
  box-shadow: 0 0 1px #FFC690;
}

input:checked + .slideres:before {
  -webkit-transform: translateX(32px);
  -ms-transform: translateX(32px);
  transform: translateX(32px);
}

/* Rounded sliders */
.slideres.round {
  border-radius: 34px;
  height: 30px;
  width: 56px;
}

.slideres.round:before {
  border-radius: 50%;
}

.sliderText{
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-top: 2px;
    font-size: 10px;
    color: #535D6D;
    font-weight: 600;
    font-family: Montserrat;
    height:15px;
}
.offButton{
    padding-left:4px;
    color: #A6A6A6
}
.acc .MuiAccordionSummary-root {
    /* padding: 0px 0px 0px 15px  !important; */
    min-height: 0px !important;
    margin-bottom: 0px !important;
    width: 100%
 }
 
 .acc .MuiAccordionSummary-root.Mui-expanded {
   min-height : 0px !important
 }
 
 .acc .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
     display: flex !important;
 }
 
 .acc .MuiAccordionDetails-root {
     display: block !important;
     padding: 0px 0px;
 }
 
 
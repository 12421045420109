@media (max-width:992px){
  .leftBar{
    width: 100% !important;
      float: none !important;
  }
  .rightBar{
    width: 100% !important;
    float: none !important;
    display: block !important;
    text-align: left !important;
  }
  .rightBar ul{
    float: left !important;
    font-size: 16px !important;
    margin-top: 30px !important;
    padding-left: 0 !important;
  }
}

.faqbox .accordion-container .accordion-set > a .header-arrow { float: right;color: #676767; font-size: 19px; 
  font-weight: 600;  margin: 2px 5px 0px 10px; width:30px; position: absolute;
  top: 18px;
  right: 10px;} 
  

.faqbox .accordion-container .qns-container{
  position: relative;
                      }
          
                      @media (max-width: 767px) {
                        .faqbox .accordion-container .accordion-set>a .faq-arrow {
                          margin: 0px 15px 0px 15px;
                          position: absolute;
                          top: 15px;
                          right: 0px;
                          width: 30px;
                        }
                        .faqbox .accordion-container .accordion-set>a.active,
                        .faqbox .accordion-container .accordion-set:first-child>a.active {
                          padding: 15px 30px 10px 15px;
                        }
                        .faqbox .accordion-container .accordion-set .inner-content {
                          padding: 5px 0px 5px 0px;
                          border-bottom: 6px solid #25ABC7;
                        }
                      }
                      
                      .faqbox .accordion-container .accordion-set>a .faq-arrow-up {
                        transform: rotate(-180deg);
                      }
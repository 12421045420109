.full-container { max-width: 1224px; margin-left: auto; margin-right: auto; }     
.escrowfaqbox h1 { font-family: 'Montserrat', sans-serif; 
font-size:24px; letter-spacing: 0.6px; margin-top: 10px; 
margin-bottom:30px; font-weight:700; color:#727272; line-height: 29px;}     
.escrowfaqbox p { font-family: 'Montserrat', sans-serif; font-size:16px; letter-spacing: 0.4px;
margin: 0; font-weight:400; color:#202020; line-height: 19px;}     
.escrowfaqbox .accordion-container { position: relative; width: 100%; height: 
auto; margin: 35px auto 35px; font-family: 'Montserrat', sans-serif; }     
.escrowfaqbox .accordion-container .accordion-set { position: relative; width: 1100px; height: auto;}    
.escrowfaqbox .accordion-container .accordion-set > a { display: block; padding: 22px 45px 22px 15px; 
font-size:16px; letter-spacing: 0.4px; line-height: 19px; text-decoration: none; 
color: #333333; font-weight: 600; border-bottom: 3px solid #25ABC7; font-family: 
'Inter', sans-serif; -webkit-transition: all 0.2s linear; -moz-transition: all 0.2s linear; transition: all 0.2s linear; }
.escrowfaqbox .accordion-container .accordion-set > a .faq-arrow { float: right;color: #676767; font-size: 19px; 
font-weight: 600;  margin: 2px 5px 0px 10px; width:30px; position: absolute;
top: 18px;
right: 10px;}     
.escrowfaqbox .accordion-container .accordion-set > a.active { background-color: transparent; 
border-bottom: none; padding: 38px 15px 20px 15px;}     
.escrowfaqbox .accordion-container .accordion-set:first-child > a.active { padding: 22px 15px;}    
.escrowfaqbox .accordion-container .accordion-set .inner-content { background-color: #fff; 
padding: 10px 0px 25px 0px; border-bottom: 3px solid #25ABC7; display: none; 
font-family: 'Montserrat', sans-serif; }     
.escrowfaqbox .accordion-container .accordion-set .inner-content p { 
padding: 0px 40px 10px 15px; margin: 0; color: #202020; 
font-size:16px; letter-spacing: 0.4px; line-height: 19px; 
font-weight: 400; font-family: 'Montserrat', sans-serif; }     

.escrowfaqbox .accordion-container .qns-container{
position: relative;
}
.escrowfaqbox .accordion-container .qns-container > a.faq-heading{
font-size: 20px;
text-decoration: none;
font-weight: bold;
display: block;
}
.escrowfaqbox .accordion-container .qns-container > a.faq-heading .header-arrow {
float: right;
color: #676767;
font-size: 19px;
font-weight: 600;
margin: 2px 5px 0px 10px;
width: 30px;
position: absolute;
top: 2px;
right: 10px;
}

.escrowfaqbox .accordion-container .qns-container > a.faq-heading .faq-arrow-up {
transform: rotate(-180deg);
}

@media (max-width: 850px) {          
.full-container { max-width: 100%; margin-left: auto; margin-right: auto; }     
.escrowfaqbox h1 { margin-bottom:10px;}     
.escrowfaqbox p { font-family: 'Montserrat', sans-serif; font-size:14px; 
letter-spacing: 0.3px; line-height: 18px;}     
.escrowfaqbox .accordion-container { margin: 15px auto 15px; font-family: 'Montserrat', sans-serif; }    
.escrowfaqbox .accordion-container .accordion-set { position: relative; width: 100%; height: auto;}
.escrowfaqbox .accordion-container .accordion-set > a { 
padding: 15px 40px 15px 15px; border-bottom: 6px solid #25ABC7;}     
.escrowfaqbox .accordion-container .accordion-set > a .faq-arrow { 
margin: 0px 15px 0px 15px; position: absolute; top: 15px; right: 0px; width:30px;}     
.escrowfaqbox .accordion-container .accordion-set > a.active, .escrowfaqbox .accordion-container 
.accordion-set:first-child > a.active { padding: 15px 30px 10px 15px;}     
.escrowfaqbox .accordion-container .accordion-set .inner-content { 
padding: 5px 0px 5px 0px; border-bottom: 6px solid #25ABC7; }
}

.escrowfaqbox .accordion-container .accordion-set > a .faq-arrow-up {
transform: rotate(-180deg);
}
 
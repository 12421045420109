textarea:focus { 
    outline: none !important;
    /* border-color: rgb(40, 141, 193); */
    /* box-shadow: 0 0 10px rgb(40, 141, 193); */
    border-radius: 4px;
}

textarea:hover { 
    /* border-color: rgb(40, 141, 193); */
    
}
.tooltipText{
font-size: 10px;
font-weight:700;
position: absolute;
display: none;
margin-top:48px;
line-height:normal;
/* z-index:10000; */
/* margin-left:10px; */
padding:16px;

/* background-color: #FFD63D; */
color: rgb(77, 77, 77);
text-align: center;
padding: 5px 5px;
border-radius: 6px;
margin-left: 6px;
}
/* .MuiRating-label{
    width:50px;
} */
/* .sentimentIcons{
    height:48px !important;
    width:48px !important;
    fill: #D9D9D9 !important;
} */

.MuiRating-iconActive .tooltipText{
    display: block;
}
 .MuiRating-iconFocus{
    color : rgba(0, 0, 0, 0.26);
} 
.MuiRating-iconActive{
    color : #FFD63D;
}

/* .iconStyle {
    position: 'fixed';
    bottom: '4px';
    height: '26px';
    width: '26px';
    z-index: '999';
    right: 200px;
}

@media (max-width: 780px){
    .iconStyle {
        right: 168px !important;
        bottom: 60px !important;
    }
} */

@media (max-width: 780px){
    .feedBtnStyle {
        padding:16px 12px 7px 46px !important;
        min-width: 181px !important;
        bottom: 56px !important;
    }
}

@media (max-width: 780px){
    .feedBtnStyles {
       
        bottom: 54px !important;
    }
}

.feedBtnStyle {
  font-family: 'Montserrat';
  font-size:16px;
  background: #34495e;
  color:#fff;
  font-weight: 600;
  z-index: 99;
  padding:16px 29px 7px 59px;
  position:fixed;
  border-radius:5px 5px 0 0;
  border: none;
  bottom:0;
  background-color:#1A4664;
  cursor: pointer;
  min-width: 222px;
  right: 8% !important;
}

.feedBtnStyles {
  padding:16px 89px 4px 44px;
  font-size:16px;
  background: #34495e;
  color:#fff;
  font-weight: 600;
  z-index: 99;
  padding:16px 29px 4px 59px;
  position:fixed;
  border-radius:5px 5px 0 0;
  border: none;
  bottom:0;
  background-color:#7EB1CC;
  cursor: pointer;
  min-width: 222px !important;
  right: 8% !important;
}


.accordian .MuiAccordionSummary-root {
    min-height: 0px !important;
    width: 100%
 }
 
 .accordian .MuiAccordionSummary-root.Mui-expanded {
   min-height : 0px !important
 }
 
 .accordian .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
     display: flex !important;
 }
 
 .accordian .MuiAccordionDetails-root {
     display: block !important;
     padding: 8px 30px;
 }
 
 
/* .container{
    width:100%;
}

.about-us {
    height: 49px;
    width: 320px;
    color: #3064a6;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 49px;
    text-shadow: 1px 1px 11px 0 rgba(0,0,0,0.5);
    margin: 25px auto;
    
  }
  .rectangle_1{
    box-sizing: border-box;
    height: 313px;
    width: 1020px;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    margin: 0 auto;
    position:relative;
    margin-bottom: 50px;
    background-color: #FFFFFF;
  }
  .rectangle_3{
    box-sizing: border-box;
    height: 313px;
    width: 1020px;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    margin: 0 auto;
    position:relative;
    margin-bottom: 50px;
    background-color: #FFFFFF;
    margin-top: 50px;
  }
  .rectangle_1>.pics{
    width:267px;
    height:235px;
    position: absolute;
    top:40px;
    left:-104px;
}

.rectangle_3>.pics{
  width:267px;
  height:235px;
  position: absolute;
  top:40px;
  left:-105px;
}

  .rectangle_2{
    box-sizing: border-box;
    background-color: #FFFFFF;
    margin-bottom: 50px;
    width: 1020px;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    margin: 0 auto;
    position:relative;
  }

  .rectangle_2>.pics{
    width:267px;
    height:235px;
    position: absolute;
    top:36px;
    right:-101px;
  }




  .incider_text{
      width:714px;
      margin-top: 68px;
      margin-left: 228px;
  }
  .incider_text_2{
    width:714px;
    margin-top: 75px;
    margin-bottom: 75px;
    margin-left: 78px;
}
.incider_text_2>span{
    
    width: 100%;
    color: #535D6D;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 24px;
  }
  .incider_text_2>ul{
    
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 35px;
  }
  .incider_text>span{
    
    width: 100%;
    color: #535D6D;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 24px;
  }
  .incider_text>p{
    
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .click-here-to-view-o {
    
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
  }

  .leadership{
      width:100%;
  }
  .leadershipcontainer{
      max-width:1300px;
      width: 1280px;
      margin-top: 190px;
  }
  .area{
      width:100%;
        height:150px
  }
  .incr{
      width:357px !important;
  }
  .division_leader{
    
    box-sizing: border-box;
    background-color: #FFFFFF;
    width: 384px;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    float:left;
    margin-right: 20px;
    position: relative;
  }
  .division_leader > .pics{
    width: 228px;
    height: 228px;
    position: absolute;
    bottom: 81px;
    right: 75px;
  }
  .division_leader > .textarea{
      width: 328px;
      margin: 0 auto;
      margin-top: 81px;
      text-align: center;

  }
  .division_leader > .textarea > span{
    color: rgba(0,0,0,0.87);
    font-family: Montserrat;
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 24px;
    
  }
  .division_leader > .textarea > p{
    color: rgba(0,0,0,0.87);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: center;
  }
  .leadership>span{
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 49px;
    text-shadow: 1px 1px 11px 0 rgba(0,0,0,0.5);
    margin: 45px auto;
  }



  @media (max-width:600px){

    .rectangle_1{
        box-sizing: border-box;
        height: auto;
        width: 331px;
        border: 2px solid #F7B15C;
        border-radius: 12px;
        margin: 0 auto;
        position:relative;
        margin-bottom: 50px;
        background-color: #FFFFFF;
        margin-top: 105px;
      }

      .rectangle_3{
        box-sizing: border-box;
        height: auto;
        width: 331px;
        border: 2px solid #F7B15C;
        border-radius: 12px;
        margin: 0 auto;
        position:relative;
        margin-bottom: 50px;
        background-color: #FFFFFF;
        margin-top: 105px;
        left:0px !important;
      }
      .container{
        transform: none !important;
    }
      .rectangle_1>.pics{
        width: 267px;
        height: 235px;
        position: absolute;
        top: -86px;
        left: 28px;
    }
    .rectangle_3>.pics{
      width: 267px;
      height: 235px;
      position: absolute;
      top: -86px;
      left: 28px;
  }
    .rectangle_2{
        box-sizing: border-box;
        background-color: #FFFFFF;
        margin-bottom: 50px;
        width: 331px;
        
        border: 2px solid #F7B15C;
        border-radius: 12px;
        margin: 0 auto;
        position:relative;
        right:0px !important;
        margin-top: 110px;
      }

      .movvdwn{
        margin-top: 50px !important;
      }
    
      .rectangle_2>.pics{
        width:267px;
        height:235px;
        position: absolute;
        top:-86px;
        left:28px;
      }
    .incider_text{
        width: 300px;
        margin-top: 172px;
        margin-left: 13px;
    }
    .incider_text_2{
        width:300px;
        margin-top: 232px;
        
        margin-left: 13px;
    }
    .division_leader{
        box-sizing: border-box;
        background-color: #FFFFFF;
        width: 351px;
        border: 2px solid #F7B15C;
        border-radius: 12px;
        float: none;
        position: relative;
        
        margin-bottom: 225px;
      }
      .division_leader > .pics{
        width: 228px;
        height: 228px;
        position: absolute;
        bottom: 81px;
        right: 59px;
      }
      .division_leader > .textarea{
          width: 271px;
          margin: 0 auto;
          margin-top: 81px;
          text-align: center;
    
      }
      .division_leader > .textarea > span{
        color: rgba(0,0,0,0.87);
        font-family: Montserrat;
        font-size: 20px;
        letter-spacing: 0.15px;
        line-height: 24px;
        
      }
      .division_leader > .textarea > p{
        color: rgba(0,0,0,0.87);
        font-family: Montserrat;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        text-align: center;
      }

      .area {
        width: 100%;
        height:auto;
    }

      .leadershipcontainer {
        position: relative; 
         right: 0px !important;
         width:355px;
         margin:0 auto;
         margin-top: 180px;
    }
  } */
  /* @media (max-width:1024px){

    .container{
        transform: scaleX(0.7);
    }
    .rectangle_2{
        right:84px;
    }
    .leadershipcontainer{
        position: relative;
        right: 148px;
    }
  } */
  .about-us {
    height: 49px;
    width: 320px;
    color: #3064a6;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 49px;
    text-shadow: 1px 1px 11px 0 rgba(0,0,0,0.5);
    margin: 25px auto;
    
  }
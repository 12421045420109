.root {
  width: 100%;
  min-height: 90vh;
}

.loaderRoot {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0,0,0,0.5);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroSection {
  width: 100%;
  min-height: 71em;
  background-color:#FFF;
}

.heroSectionPlaceHolder {
  width: 100%;
}

.wrapper {
  width: 100%;
  height: 577px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ddd;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.bannerWrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  display: flex !important;
}

.bannerContentContainer {
  position: absolute;
  width: 100%;
  max-width: 120em;
  padding: 14em 0 0 16em; 
  margin: 0 auto;
}

.bannerWrapper img {
  display: block;
  width: 100%;
  height: auto;
}

.contentContainer {
  width: 100%;
  max-width: 120em;
  padding: 0 4.7em;
  margin: 0 auto;
}

.titleBanner { 
  font-size: 18px;
  color: #121D22;
  font-family: Montserrat;
  font-weight: 600;
  padding-left: 4px;
}

.headerText {
  color: #1A4664;
  font-family: 'Montserrat';
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 34px;
  text-shadow: 1px 1px 11px 0 rgba(0,0,0,0.5);
  padding-bottom: 15px;
}

.contentText {
  color: #1A4664;
  font-family: 'Montserrat';
  font-size: 2.8em;
  font-weight: 500;
  margin-bottom: 10px;
  /* padding-top: 20px; */
  width: 684px;
}

.buttonText {
  height: 40;
  width: 300;
  font-family: 'Montserrat';
  font-size: 1.6em;
  font-weight: 600;
  margin-top: 30px !important;
}

.heroTitle {
  color: #FFFFFF;
  font-family: 'Montserrat';
  font-size: 4em;
  font-weight: bold;
  /* margin-bottom: 5px; */
  letter-spacing: 1.5px;
  line-height: 34px;
  text-shadow: 1px 1px 11px 0 rgba(0,0,0,0.5);
}

.heroSubTitle {
  color: #FFFFFF;
  font-family: 'Montserrat';
  font-size: 2.8em;
  font-weight: 500;
  margin-bottom: 25px;
}

.heroBtn {
  height: 40;
  width: 282;
  font-family: Montserrat;
  font-size: 1.6em;
  font-weight: 600;
}


.heroFooter {
  width: 100%;
  max-width: 140em;
  padding: 0 4.7em;
  margin: -92px auto 0px;
}


.heroFooterTitle {
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 2.8em;
  font-weight: 600;
  text-shadow: 1px 2px 4px 0 rgba(0,0,0,0.25);
}

.cardContainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.cardLink {
  display: block;
  text-decoration: none;
}

#card {
  width: 21em;
  max-width: 21em;
  min-height: 15.3em;
  padding: 22px 10px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  border-radius: 12px;
  margin: 15px 0;
  overflow: hidden;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
}

.arrowIcon {
  color: #fff;
  position: absolute;
  width: 67px;
  height: 21px;
  left: -40%;
  bottom: 20px;
  opacity: 0;
  transition: all 0.3s;
}

#card:hover {
  height: 180px;
}

#card:hover .arrowIcon {
  opacity: 1;
  bottom: 20px;
  left: 36%;
}

.cardImg {
  display: block;
  width: 54px;
  height: 54px;
  margin: 0 auto 9px;
}

.cardTitle {
  color: #FFF;
  letter-spacing: 1.43px;
  font-family: Montserrat;
  font-size: 1.6em;
  font-weight: 600;
  text-align: center;
  /* max-width: 160px; */
}


/*==================*/

.loanSection {
  position: relative;
  width: 100%;
  background-color:#FFF;
  z-index: 1;
}

.loanSection:before{
  background: inherit;
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  transform: skewY(-2.5deg);
  transform-origin: 100%;
}


.loanContainer {
  width: 100%;
  max-width: 120em;
  padding: 8.7em 4.7em 8em;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.img {
  width: 100%;
  height: 100%;
}

.imgContainer {
  max-width: 433px;
  height: 240px;
  margin-right: 19px;
}

.content {
  max-width: 50%;
}

.title {
  color: #535D6D;
  font-family: Montserrat;
  font-size: 4.8em;
  font-weight: bold;
  line-height: 1.2;
}

.subTitle {
  color: #202020;
  font-family: Montserrat;
  font-size: 2.4em;
  font-weight: 400;
  letter-spacing: 0.18px;
  margin: 15px 0;
}

.text{
  color: #000000;
  font-family: Montserrat;
  font-size: 1.6em;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  font-weight: 400;
}


/*==================*/


.whoWeAreSection {
  width: 100%;
}

.whoWeAreContainer {
  width: 100%;
  max-width: 120em;
  padding: 14.5em 4.7em 18em;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.whoWeAreContent {
  max-width: 50%;
  margin-Right: 19px;
}

.whoWeAreImgContainerMobile {
  width: 433px;
  height: 340px;
  margin-left: auto;
  margin-right: auto;
  display: none;
}

.whoWeAreImgContainer {
  width: 433px;
  height: 340px;
  margin-right: 19px;
}

.covidSection {
  width: 100%;
  background-color: #fff;
  position: relative;
  z-index: 1;
  background: url('../../assets/LandingPage/COVIDBanner.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.covidSection:after {
  background: inherit;
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  transform-origin: bottom right;
  transform: skewY(2.5deg);
}

.covidContainer {
  width: 100%;
  padding: 13.4em 4.7em;
  max-width: 120em;
  margin: 0 auto;
}

.covidTitle {
  color: #F2C777;
  font-family: Montserrat;
  font-size: 3.6em;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 1px 1px 11px 0 rgba(0,0,0,0.77); 
  max-width: 60%;
}

.covidSubTitle {
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 1.6em;
  margin-bottom: 4em;
  max-width: 33em;
}

.covidBtn {
  height: 40px;
  width: 160px;
  border-radius: 4px;
  background-color: #25ABC7;
  border: solid 1px #25ABC7;
  color: #fff;
  font-size: 1.6em;
  cursor: pointer;
  outline:none;
}

/*=============*/

.didUKnowSection {
  width: 100%;
  position: relative;
  z-index: 1;
}

.didUKnowSection:after {
  background: inherit;
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  transform-origin: bottom right;
  transform: skewY(2.5deg);
}

.didUKnowContainer {
  width: 100%;
  max-width: 120em;
  padding: 7.9em 4.7em;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.didUKnowImgContainer {
  height: 348px;
  width: 441px;
  margin-right: 1.9em;
}

.slick-slide {
  height:580px;
  width: 100%;
}

.slick-slide img {
  width: 100%;
  object-fit: cover;

}

.acc1 .slider {
  height: 580px;
} 

.acc1 .slider img{ width: 100%;  height: 580px; object-fit: cover;} 

@media only screen and (max-width: 1050px) {

  .slick-slide {
  height: auto;
  width: 100%;
}

.slick-slide img {
  width: 100%;
  object-fit: cover;

}

.acc1 .slider {
  height: auto;
} 

.acc1 .slider img{ width: 100%;     height: auto; object-fit: cover;} 
   
  .titleBanner { 
    font-size: 14px;
    color: #121D22;
    font-family: Montserrat;
    font-weight: 600;
    padding-left: 4px;
  }

  .headerText {
    font-size: 18px;
    padding-bottom: 0px;
  }
  
  .contentText {
    font-size: 20px;
    width: 360px;
  }
  
  .buttonText {
    margin-top: 0px !important;
  }

  .bannerContentContainer {
    padding: 2em 0 0 8em; 
  }

  .contentContainer {
    padding: 0 1.6em;
    margin-top: 200px;
  }

  .heroFooter {
    margin: -44px auto 0px;
    padding: 0 1.6em;
  }


  .heroTitle {
    font-size: 2.7em;
  }

  .heroSubTitle {
    font-size: 1.6em;
  }

  .heroFooterTitle {
    display: none;
  }
  
  .wrapper {
    height: 530px;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  #card {
    width: 100%;
    max-width: 100%;
    min-height: 7em;
    padding: 12px 10px;
    border-radius: 10px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0 0 15px 0;
  }

  .cardImg {
    display: block;
    margin: 0 15px 0 0;
  }
  
  #card:hover {
    height: auto;
  }

  #card:hover .arrowIcon {
    display: none;
  }

  .imgContainer {
    max-width: 433px;
    height: 240px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 15px;
  }

  .content {
    max-width: 100%;
  }

  .title {
    text-align: center;
    font-size: 3.6em;
  }

  .subTitle {
    text-align: center;
  }

  .text {
    text-align: center;
  }

  .whoWeAreContent {
    max-width: 100%;
    margin-right: 0;
  }

  .whoWeAreImgContainerMobile {
    width: 433px;
    height: 340px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin: 0 auto 15px;
  }

  .whoWeAreImgContainer {
    display: none;
  }

  .whoWeAreContainer {
    padding: 4.7em 1.6em 8em;
  }

  .covidContainer {
    padding: 4.7em 1.6em 8em;
  }

  .didUKnowContainer {
    padding: 4.7em 1.6em 8em;
  }

  .covidTitle {
    font-size: 2.4em;
    max-width: 95%;
  }

  .didUKnowImgContainer {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width:1051px) and (max-width:1265px) {
  #card {
    width: 17em;
    max-width: 17em;
    padding: 1.6em;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    border-radius: 12px;
    margin: 15px 0;
    overflow: hidden;
    transition: 0.1s;
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .headerText {
    font-size: 20px;
    padding-bottom: 0px;
    line-height: 26px;
  }
  
  .contentText {
    font-size: 20px;
    width: 360px;
  }
  
  .buttonText {
    margin-top: 0px !important;
  }

  .bannerContentContainer {
    padding: 23em 0 0 3.5em; 
  }
}

.slick-dots, .slick-next, .slick-prev {
  position: fixed;
}


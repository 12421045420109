.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  /* height: 34px; */
  height: 30px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.38);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0px;
  /* bottom: 4px; */
  background-color: #3DBA21;
  -webkit-transition: .4s;
  transition: .4s;  
}


input + .slider:before {
  background-color:#fff;
  border: 1px solid #c6c4c4;
}

input:checked + .slider:before {
  background-color: #3DBA21;
  border: 'none';
}

input:checked + .slider {
  background-color: rgba(61,186,33,0.38);
}


input:focus + .slider {
  box-shadow: 0 0 1px '#92CC79';
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.textAligment{
    display: flex;
    align-items: center;
    padding-left: 6px;
    font-size: 6px;
    color: #535D6D;
    font-weight: 600;
    font-family: Montserrat;
    height:15px;
}
.off{
    padding-left:11px;
}

/* Css for faq switch button */

.faqVideo {
  background-color: rgba(0,0,0,0.38);
  opacity: 1;
  color:#ffffff;

}

.faqVideo:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0px;
  background-color: #EDB36A;
  -webkit-transition: .4s;
  transition: .4s;  
}


input + .faqVideo:before {
  background-color:#fff;
  border: 1px solid #c6c4c4;
}

input:checked + .faqVideo:before {
  background-color: #EDB36A;
  border: 'none';
}


input:checked + .faqVideo {
  background-color: #EDB36A;
  padding-left: 9px;
}



input:focus + .faqVideo {
  box-shadow: 0 0 1px #EDB36A;
}

input:checked + .faqVideo:before {
  -webkit-transform: translateX(33px);
  -ms-transform: translateX(33px);
  transform: translateX(33px);
}

.faqVideo.round {
  border-radius: 34px;
}

.faqVideo.round:before {
  border-radius: 50%;
}

.textAligment.faqVideo{
    display: flex;
    align-items: center;
    padding-left: 3px;
    font-size: 10px;
    color: #535D6D;
    height:26px;
    font-weight:700;
    width:60px;
    background: #D9D9D9;
}

input:checked + .faqVideo:before {
  background-color: #ffffff;
  border: 'none';
}

/* Css for pause additional principal switch  */

.pauseAdditionalPayment {
  background-color: rgba(0,0,0,0.38);
  opacity: 1;
  color:#ffffff;

}

.pauseAdditionalPayment:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0px;
  background-color: #92CC79;
  -webkit-transition: .4s;
  transition: .4s;  
}


input + .pauseAdditionalPayment:before {
  background-color:#fff;
  border: 1px solid #c6c4c4;
}

input:checked + .pauseAdditionalPayment:before {
  background-color: #92CC79;
  border: 'none';
}


input:checked + .pauseAdditionalPayment {
  background-color: #92CC79;
  padding-left: 9px;
}



input:focus + .pauseAdditionalPayment {
  box-shadow: 0 0 1px #92CC79;
}

input:checked + .pauseAdditionalPayment:before {
  -webkit-transform: translateX(33px);
  -ms-transform: translateX(33px);
  transform: translateX(33px);
}

.pauseAdditionalPayment.round {
  border-radius: 34px;
}

.pauseAdditionalPayment.round:before {
  border-radius: 50%;
}

.textAligment.pauseAdditionalPayment{
    display: flex;
    align-items: center;
    padding-left: 3px;
    font-size: 10px;
    color: #535D6D;
    height:26px;
    font-weight:700;
    width:60px;
    background: #D9D9D9;
}

input:checked + .pauseAdditionalPayment:before {
  background-color: #ffffff;
  border: 'none';
}


.rightBar ul li{
  text-transform: uppercase;
} 

@media (max-width:992px){
  .leftBar{
    width: 100% !important;
      float: none !important;
  }
  .rightBar{
    width: 100% !important;
    float: none !important;
    display: block !important;
    text-align: left !important;
  }
  .rightBar ul{
    float: left !important;
    font-size: 16px !important;
    margin-top: 30px !important;
    padding-left: 0 !important;
  }
}

.faqAccordian .MuiAccordionSummary-root {
    padding: 0px 0px 0px 0px  !important;
    min-height: 0px !important;
    margin-bottom: 0px !important;
    width: 100%
    /* width: 1034px !important; */
 }
 
 .faqAccordian .MuiAccordionSummary-root.Mui-expanded {
   min-height : 0px !important
 }
 
 .faqAccordian .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
     display: flex !important;
 }
 
 .faqAccordian .MuiAccordionDetails-root {
     display: block !important;
     padding: 8px 0px;
 }

 .faqAccordian .MuiAccordionSummary-content {
     margin: 0px !important;
 }
.faqAccordian{
     margin-top: 20px; 
}
.faqAccordian p {
    margin: 0;
    color: #202020;
    font-size: 16px !important;
    letter-spacing: .4px;
    line-height: 19px;
    font-weight: 400;
    font-family: "Montserrat",sans-serif; 
}

* {
  box-sizing: border-box;
}

html {
  max-width: 100%;
  font-size: 16px;
}

body {
  max-width: 100%;
  font-size: 62.5% !important;
  font-weight: normal !important;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  margin:0;
  align-items: normal !important;
  justify-content: normal !important;
  display: block !important;
}

section, header, footer, nav, article, aside, div {
  /* display: block; */
}

p {
  margin: 0 ;
  padding:0 ;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding:0;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


/*Scroll Bar*/
/* width */
::-webkit-scrollbar {
  width: 15px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;  
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(114,114,114,0.42);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(114,114,114,0.42);
}

/*Error Message*/
.errorMessage{    
    color: #E30909;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.36px;
    line-height: 19px;  
}

.successMessage{    
  color: #22d461;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 19px;  
}

.five9-header {
  /* padding-right: 10px; */
  width: auto;
  background: #7EB1CC;    
  border-radius: 4px 4px 4px 0px;
  /* border-right: 1px solid black; */
}

.five9-header .five9-email-button {
  width: 100%;
  height: 50px;
  background: #7EB1CC;  
  padding: 0;
  display: flex !important;
  align-items: center !important; 
  border-radius: 5px 5px 0 0;
}

.five9-email-button .five9-icon {
  position: relative !important;
  top: 0px !important;
  margin-right: 15px !important;
}

.five9-email-button .five9-text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  width: 62%;
  text-align: left;
}

.five9-email-button .five9-text::after {
  content: 'Now';
  margin-left: 5px;
}

#five9-action-btn {
  display: none;
}
.five9-frame-full {
  bottom: -36px !important;
}

.five9-frame .five9-frame-full #embedded-frame {
  margin-top: -34px;
  max-height: 100vh;
}

.five9-action-btn, .five9-aux-btn {
  background: #7EB1CC !important;
}

.five9-frame {
  /* width: 185px !important; */
  /* right: 20% !important; */
  right: 8% !important;
  position: fixed;
  /* height: 36px !important; */
  /* z-index: 90 !important; */
}


@media (max-width: 780px){

.five9-frame {

  right: 8%;
  width: auto !important;
  position: fixed !important;
  /* bottom: 54px !important; */


    /* right: 211px !important; 
width: auto !important; 
    position: fixed !important;
     bottom: 0px !important; */
}
}

.bot-trained-on {
  background-color: #f9f9f9; /* Lighter gray background */
  color: #333;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 40px;
  text-align: left;
}
.bot-trained-on h2 {
  color: #2e86c1; /* AI bot related theme color for headings */
}
.bot-trained-on ul {
  margin-left: 20px;
}
.container{
  
    max-width: 1200px;
}


.about-us {
    height: 49px;
    width: 206px;
    color: #FFFFFF !important;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 49px;
    text-shadow: 1px 1px 11px 0 rgba(0,0,0,0.5) !important;
    margin: 25px auto;
    
  }
  .rectangle_1{
    box-sizing: border-box;
    width:83%;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    
    margin: 0 auto;
    position:relative;
    margin-bottom: 50px;
    background-color: #FFFFFF;
  }
  .rectangle_3{
    box-sizing: border-box;
    width:83%;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    margin: 0 auto;
    position:relative;
    margin-bottom: 50px;
    background-color: #FFFFFF;
    margin-top: 50px;
  }

  .rectangle_3>.pics{
    width:267px;
    height:235px;
    position: absolute;
    top:40px;
    left:-105px;
  }
  .rectangle_1>.pics{
    width:267px;
    height:235px;
    position: absolute;
    
    top:40px;
    left:-104px;
}
.pics img{
 
  width: 100%;
  height: auto;
  border-radius: 20px;
  border-right: 6px solid rgba(0,0,0,0.2);
  border-bottom: 6px solid rgba(0,0,0,0.2);
}

  .rectangle_2{
    box-sizing: border-box;
    background-color: #FFFFFF;
    margin-bottom: 50px;
    width:83%;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    margin: 0 auto;
    position:relative;
  }

  .rectangle_2>.pics{
    width:267px;
    height:293px;
    position: absolute;
    top:36px;
    right:-102px;
  }




  .incider_text{
      width:68%;
      margin-top: 68px;
      margin-left: 228px;
      margin-bottom: 55px;
  }
  .incider_text_2{
    width:68%;
    margin-top: 75px;
    margin-bottom: 75px;
    margin-left: 78px;
}
.incider_text_2>span{
    
    width: 100%;
    color: #535D6D;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 24px;
  }
  .incider_text_2>ul{
    
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 35px;
  }
  .incider_text>span{
    
    width: 100%;
    color: #535D6D;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 28px;
  }
  .incider_text>p{
    
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .incider_text_2>p{
    
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

  .click-here-to-view-o {
    
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
  }

  .leadership{
      width:100%;
  }
  .leadershipcontainer{
      max-width:1300px;
      width: 1280px;
      margin-top: 190px;
  }
  .area{
      width:100%;
        height:150px
  }
  .incr{
      width:357px !important;
  }
  .division_leader{
    
    box-sizing: border-box;
    background-color: #FFFFFF;
    width: 384px;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    float:left;
    margin-right: 20px;
    position: relative;
  }
  .division_leader > .pics{
    width: 228px;
    height: 228px;
    position: absolute;
    bottom: 81px;
    right: 75px;
  }
  .division_leader > .textarea{
      width: 328px;
      margin: 0 auto;
      margin-top: 81px;
      text-align: center;

  }
  .division_leader > .textarea > span{
    color: rgba(0,0,0,0.87);
    font-family: Montserrat;
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 24px;
    
  }
  .division_leader > .textarea > p{
    color: rgba(0,0,0,0.87);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: center;
  }
  .leadership>span{
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 49px;
    text-shadow: 1px 1px 11px 0 rgba(0,0,0,0.5);
    margin: 45px auto;
  }

  @media (max-width:1100px){

 
    .rectangle_1{
      width:79%;
      margin:0 auto;
      margin-bottom: 20px;
    }
    .rectangle_2{
      width:79%;
      margin:0 auto
    }
    .rectangle_3{
      width:79%;
      margin:20px auto
    }


  }
  @media (max-width:950px){

 .incider_text{
   width:57%;
   margin-bottom: 27px;
 }
 .incider_text_2{
  width:57%;
  margin-bottom: 0px;
  margin-left:44px;
}
.incider_text_2>ul {
  
  line-height: 27px;
}
.rectangle_3{
  
  
  width:70%;
  margin:20px auto
}

.rectangle_3>.pics{
  width:267px;
  height:235px;
  position: absolute;
  top:80px;
  left:-105px;
}

 .rectangle_1>.pics{
  width:267px;
  height:235px;
  position: absolute;
  
  top:84px;
  left:-104px;
}
.rectangle_2>.pics{
  width:267px;
  height:293px;
  position: absolute;
  top:72px;
  right:-102px;
}
    .rectangle_1{
      width:70%;
      margin:0 auto;
      margin-bottom: 20px;
    }
    .rectangle_2{
      width:70%;
      margin:0 auto
    }

  }
  @media (max-width:800px){

    .incider_text{
      width:50%;
      margin-bottom: 27px;
    }
    .incider_text_2{
      width:50%;
      /* margin-bottom: 27px; */
    }
    .rectangle_1>.pics{
     width:267px;
     height:235px;
     position: absolute;
     
     top:120px;
     left:-104px;
   }
   .rectangle_2>.pics{
    width:267px;
    height:293px;
    position: absolute;
    top:100px;
    right:-102px;
  }
       .rectangle_1{
         width:65%;
         margin:0 auto;
         margin-bottom: 20px;
       }

       .rectangle_2{
        width:65%;
        margin:0 auto
      }

      .rectangle_3{
  
  
        width:65%;
        margin:20px auto
      }
      
      .rectangle_3>.pics{
        width:267px;
        height:235px;
        position: absolute;
        top:160px;
        left:-105px;
      }
  
   
     }

     @media (max-width:750px){

      .incider_text{
        width:40%;
        margin-bottom: 20px;
        margin-left:199px;
      }

      .incider_text_2{
        width:40%;
        /* margin-bottom: 20px; */
      }

      .rectangle_2>.pics{
        width:267px;
        height:293px;
        position: absolute;
        top:226px;
        right:-102px;
      }
      
      .rectangle_1>.pics{
       width:267px;
       height:235px;
       position: absolute;
       
       top:160px;
       left:-104px;
     }
         .rectangle_1{
           width:59%;
           margin:0 auto;
           margin-bottom: 20px;
         }

         .rectangle_2{
          width:59%;
          margin:0 auto
        }
        .rectangle_3{
  
  
          width:59%;
          margin:20px auto
        }
        
        .rectangle_3>.pics{
          width:267px;
          height:235px;
          position: absolute;
          top:250px;
          left:-105px;
        }
     
       }

     @media (max-width:600px){

      .rectangle_1{
          box-sizing: border-box;
          height: auto;
          width: 331px;
          border: 2px solid #F7B15C;
          border-radius: 12px;
          margin: 0 auto;
          position:relative;
          margin-bottom: 50px;
          background-color: #FFFFFF;
          margin-top: 105px;
          left:0px !important;
        }
        .rectangle_3{
          box-sizing: border-box;
          height: auto;
          width: 331px;
          border: 2px solid #F7B15C;
          border-radius: 12px;
          margin: 0 auto;
          position:relative;
          margin-bottom: 50px;
          background-color: #FFFFFF;
          margin-top: 105px;
          left:0px !important;
        }
        .gett{
          margin-top: 130px !important;
        }
        .container{
          transform: none !important;
      }
        .rectangle_1>.pics{
          width: 267px;
          height: 235px;
          position: absolute;
          
          top: -86px;
          left: 28px;
      }
      .rectangle_2{
          box-sizing: border-box;
          background-color: #FFFFFF;
          margin-bottom: 50px;
          width: 331px;
          
          border: 2px solid #F7B15C;
          border-radius: 12px;
          margin: 0 auto;
          position:relative;
          right:0px !important;
          margin-top: 110px;
        }
        .rectangle_3>.pics{
          width: 267px;
          height: 235px;
          position: absolute;
          top: -86px;
          left: 28px;
      }
      
        .rectangle_2>.pics{
          width:267px;
          height:235px;
          position: absolute;
          top:-86px;
          left:28px;
        }
      .incider_text{
          width: 300px;
          margin-top: 172px;
          margin-left: 13px;
      }
      .incider_text_2{
          width:300px;
          margin-top: 232px;
          
          margin-left: 13px;
      }
      .division_leader{
          box-sizing: border-box;
          background-color: #FFFFFF;
          width: 351px;
          border: 2px solid #F7B15C;
          border-radius: 12px;
          float: none;
          position: relative;
          
          margin-bottom: 225px;
        }
        .division_leader > .pics{
          width: 228px;
          height: 228px;
          position: absolute;
          bottom: 81px;
          right: 59px;
        }
        .division_leader > .textarea{
            width: 271px;
            margin: 0 auto;
            margin-top: 81px;
            text-align: center;
      
        }
        .division_leader > .textarea > span{
          color: rgba(0,0,0,0.87);
          font-family: Montserrat;
          font-size: 20px;
          letter-spacing: 0.15px;
          line-height: 24px;
          
        }
        .division_leader > .textarea > p{
          color: rgba(0,0,0,0.87);
          font-family: Montserrat;
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 16px;
          text-align: center;
        }
  
        .area {
          width: 100%;
          height:auto;
      }
  
        .leadershipcontainer {
          position: relative; 
           right: 0px !important;
           width:355px;
           margin:0 auto;
           margin-top: 180px;
      }
    }

  /* @media (max-width:1200px){

    .container{
        transform: scaleX(0.9);
    }
    .rectangle_1{
      left:45px;
    }
    .rectangle_3{
      left:45px;
    }
    .rectangle_2{
        right:49px;
    }
    .leadershipcontainer{
        position: relative;
        right: 85px;
        transform: scaleX(0.95);
    }
  }

  @media (max-width:1024px){

    .container{
        transform: scaleX(0.7);
    }
    .rectangle_2{
        right:84px;
    }
    .leadershipcontainer{
        position: relative;
        right: 119px;
    }
  }
  @media only screen and (max-width: 900px) and (min-width: 710px)  {
    .container{
      transform: scaleX(0.6);
  }
  .rectangle_1{
    left:-109px;
  }
  .rectangle_2{
      right:204px !important;
  }
  .rectangle_3{
    left:-135px !important;
}
  .leadershipcontainer{
      position: relative;
      right:306px !important;
  }
  }
  @media only screen and (max-width: 700px) and (min-width: 600px)  {
    .container{
      transform: scaleX(0.55);
  }
  .rectangle_1{
    left:-109px;
  }
  .rectangle_2{
      right:204px !important;
  }
  .rectangle_3{
    left:-135px !important;
}
  .leadershipcontainer{
      position: relative;
      right:306px !important;
  }
  }


  
  @media only screen and (max-width: 600px) and (min-width: 500px)  {
    .container{
      transform: scaleX(0.55);
  }
  .rectangle_1{
    left:-109px;
  }
  .rectangle_2{
      right:189px !important;
  }
  .rectangle_3{
    left:-185px !important;
}
  .leadershipcontainer{
      position: relative;
      right:306px !important;
  }
  } */
  

.newly-boarded-container{
    max-width: 1200px;
}
.newly-boarded-about-us {
    height: 49px;
    width: 206px;
    color: #FFFFFF !important;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 49px;
    text-shadow: 1px 1px 11px 0 rgba(0,0,0,0.5) !important;
    margin: 0 auto;
  }
  .rectangle{
    box-sizing: border-box;
    width:83%;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    
    margin: 0 auto;
    position:relative;
    margin-bottom: 50px;
    background-color: #FFFFFF;
  }
 

  .rectangle_3>.pics{
    width:267px;
    height:235px;
    position: absolute;
    top:40px;
    left:-105px;
  }
  /* .rectangle>.pics{
    width:267px;
    height:235px;
    position: absolute;
    
    top:40px;
    left:-104px;
} */
.pics img{
 
  width: 100%;
  height: auto;
  border-radius: 20px;
  border-right: 6px solid rgba(0,0,0,0.2);
  border-bottom: 6px solid rgba(0,0,0,0.2);
}

  .rectangle_2{
    box-sizing: border-box;
    background-color: #FFFFFF;
    margin-bottom: 50px;
    width:83%;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    margin: 0 auto;
    position:relative;
  }

  .rectangle_2>.pics{
    width:267px;
    height:293px;
    position: absolute;
    top:36px;
    right:-102px;
  }

  .incider_text_newlyboarded{
      width:85%;
      margin-top: 50px;
      margin-bottom: 50px;
      margin-left: 78px;
  }
  
  .incider_text_newlyboarded>p {
    width: 100%;
    color: rgba(0,0,0,.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: .5px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
}
  /* .incider_text_newlyboarded>.title{
    
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    padding: 0px 140px;
  } */
 
  .title {
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
 

  .subtitle1{
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    /* padding: 0px 140px; */
    text-align: center;
  }

  .subtitle2{
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0px 230px
  }


  .subtitle3{
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .subtitle4{
    /* padding: 0 60px */
    text-align: center;
  }

  .Full_arae{

    max-width: 1280px;
    font-size: 21px;
    margin: 0 auto 20px auto;
    
    font-family: Montserrat;
}

.responsive-iframe {
   width:100%;
   height:610px;
   margin-top: -48px;
  }
p{
    /* margin: 10px 0px; */
    font-size: 19px;
}
.coverarea{
    width:100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
}
.division{
   width: 50%;
}

.division>p{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.74);
    width:70%;
}

.division h2 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.74);
}

.contactContainer {
    overflow: hidden;
}

@media (max-width:992px){

    .division{
        width:100%;
    }
  }

  .click-here-to-view-o {
    
    width: 100%;
    color: rgba(0,0,0,0.74);
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
  }

  .leadership{
      width:100%;
  }
  .leadershipcontainer{
      max-width:1300px;
      width: 1280px;
      margin-top: 190px;
  }
  .area{
      width:100%;
        height:150px
  }
  .incr{
      width:357px !important;
  }
  .division_leader{
    
    box-sizing: border-box;
    background-color: #FFFFFF;
    width: 384px;
    border: 2px solid #F7B15C;
    border-radius: 12px;
    float:left;
    margin-right: 20px;
    position: relative;
  }
  .division_leader > .pics{
    width: 228px;
    height: 228px;
    position: absolute;
    bottom: 81px;
    right: 75px;
  }
  .division_leader > .textarea{
      width: 328px;
      margin: 0 auto;
      margin-top: 81px;
      text-align: center;

  }
  .division_leader > .textarea > span{
    color: rgba(0,0,0,0.87);
    font-family: Montserrat;
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 24px;
    
  }
  .division_leader > .textarea > p{
    color: rgba(0,0,0,0.87);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: center;
  }
  .leadership>span{
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 49px;
    text-shadow: 1px 1px 11px 0 rgba(0,0,0,0.5);
    margin: 45px auto;
  }

  @media (max-width:1100px){

 
    .rectangle{
      width:79%;
      margin:0 auto;
      margin-bottom: 20px;
    }
    .rectangle_2{
      width:79%;
      margin:0 auto
    }
    .rectangle_3{
      width:79%;
      margin:20px auto
    }


  }
  @media (max-width:950px){

 .incider_text_newlyboarded{
   width:57%;
   margin-bottom: 27px;
 }
.incider_text_2>ul {
  
  line-height: 27px;
}
.rectangle_3{
  
  
  width:70%;
  margin:20px auto
}

.rectangle_3>.pics{
  width:267px;
  height:235px;
  position: absolute;
  top:80px;
  left:-105px;
}

 .rectangle>.pics{
  width:267px;
  height:235px;
  position: absolute;
  
  top:84px;
  left:-104px;
}
.rectangle_2>.pics{
  width:267px;
  height:293px;
  position: absolute;
  top:72px;
  right:-102px;
}
    .rectangle{
      width:70%;
      margin:0 auto;
      margin-bottom: 20px;
    }
    .rectangle_2{
      width:70%;
      margin:0 auto
    }

  }
  @media (max-width:800px){
   

    .incider_text_newlyboarded{
      width:50%;
      margin-bottom: 27px;
      margin-top: 13px;
    }
    .rectangle>.pics{
     width:267px;
     height:235px;
     position: absolute;
     
     top:120px;
     left:-104px;
   }
   .rectangle_2>.pics{
    width:267px;
    height:293px;
    position: absolute;
    top:100px;
    right:-102px;
  }
       .rectangle{
         width:65%;
         margin:0 auto;
         margin-bottom: 20px;
       }

       .rectangle_2{
        width:65%;
        margin:0 auto
      }

      .rectangle_3{
  
  
        width:65%;
        margin:20px auto
      }
      
      .rectangle_3>.pics{
        width:267px;
        height:235px;
        position: absolute;
        top:160px;
        left:-105px;
      }
  
      .incider_text_newlyboarded>span{
    
        width: 100%;
        color: #535D6D;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.18px;
        line-height: 28px;
        padding: 0 
      }
  
      .subtitle1{
        width: 100%;
        color: rgba(0,0,0,0.74);
        font-family: Montserrat;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0px;
      }
    
      .subtitle2{
        width: 100%;
        color: rgba(0,0,0,0.74);
        font-family: Montserrat;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0px 
      }
     
      .subtitle4{
        padding: 0px
      }
     }

     @media (max-width:750px){

      .incider_text_newlyboarded{
        width:40%;
        margin-bottom: 20px;
        margin-left:199px;
      }

      .rectangle_2>.pics{
        width:267px;
        height:293px;
        position: absolute;
        top:226px;
        right:-102px;
      }
      
      .rectangle>.pics{
       width:267px;
       height:235px;
       position: absolute;
       
       top:160px;
       left:-104px;
     }
         .rectangle{
           width:59%;
           margin:0 auto;
           margin-bottom: 20px;
         }

         .rectangle_2{
          width:59%;
          margin:0 auto
        }
        .rectangle_3{
  
  
          width:59%;
          margin:20px auto
        }
        
        .rectangle_3>.pics{
          width:267px;
          height:235px;
          position: absolute;
          top:250px;
          left:-105px;
        }
     
       }

     @media (max-width:600px){

      .rectangle{
          box-sizing: border-box;
          height: auto;
          width: 331px;
          border: 2px solid #F7B15C;
          border-radius: 12px;
          margin: 0 auto;
          position:relative;
          margin-bottom: 50px;
          background-color: #FFFFFF;
          margin-top: 105px;
          left:0px !important;
        }
        .rectangle_3{
          box-sizing: border-box;
          height: auto;
          width: 331px;
          border: 2px solid #F7B15C;
          border-radius: 12px;
          margin: 0 auto;
          position:relative;
          margin-bottom: 50px;
          background-color: #FFFFFF;
          margin-top: 105px;
          left:0px !important;
        }
        .gett{
          margin-top: 130px !important;
        }
        .newly-boarded-container{
          transform: none !important;
      }
        .rectangle>.pics{
          width: 267px;
          height: 235px;
          position: absolute;
          
          top: -86px;
          left: 28px;
      }
      .rectangle_2{
          box-sizing: border-box;
          background-color: #FFFFFF;
          margin-bottom: 50px;
          width: 331px;
          
          border: 2px solid #F7B15C;
          border-radius: 12px;
          margin: 0 auto;
          position:relative;
          right:0px !important;
          margin-top: 110px;
        }
        .rectangle_3>.pics{
          width: 267px;
          height: 235px;
          position: absolute;
          top: -86px;
          left: 28px;
      }
      
        .rectangle_2>.pics{
          width:267px;
          height:235px;
          position: absolute;
          top:-86px;
          left:28px;
        }
      .incider_text_newlyboarded{
          width: 300px;
          margin-top: 20px;
          margin-left: 13px;
      }
      .division_leader{
          box-sizing: border-box;
          background-color: #FFFFFF;
          width: 351px;
          border: 2px solid #F7B15C;
          border-radius: 12px;
          float: none;
          position: relative;
          
          margin-bottom: 225px;
        }
        .division_leader > .pics{
          width: 228px;
          height: 228px;
          position: absolute;
          bottom: 81px;
          right: 59px;
        }
        .division_leader > .textarea{
            width: 271px;
            margin: 0 auto;
            margin-top: 81px;
            text-align: center;
      
        }
        .division_leader > .textarea > span{
          color: rgba(0,0,0,0.87);
          font-family: Montserrat;
          font-size: 20px;
          letter-spacing: 0.15px;
          line-height: 24px;
          
        }
        .division_leader > .textarea > p{
          color: rgba(0,0,0,0.87);
          font-family: Montserrat;
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 16px;
          text-align: center;
        }
  
        .area {
          width: 100%;
          height:auto;
      }
  
        .leadershipcontainer {
          position: relative; 
           right: 0px !important;
           width:355px;
           margin:0 auto;
           margin-top: 180px;
      }
    }

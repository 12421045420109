.acc1 .MuiAccordionSummary-root {
   padding: 0px 0px 0px 15px  !important;
   min-height: 0px !important;
   margin-bottom: 10px !important;
   width: 100%
   /* width: 1034px !important; */
}

.acc1 .MuiAccordionSummary-root.Mui-expanded {
  min-height : 0px !important
}

.acc1 .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    display: flex !important;
}

.acc1 .MuiAccordionDetails-root {
    display: block !important;
    padding: 8px 0px;
}

